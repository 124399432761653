import React from 'react'
import UploadProof from '../controllers/UpoladProof'

export default function UploadProofPage() {
  return (
    <div>
      <UploadProof />
    </div>
  )
}
