import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VehiclePage from "./pages/VehiclePage";
import UploadPage from "./pages/UploadPage";
import UploadProofPage from "./pages/UploadProofPage";
import NoTransKey from "./pages/NoTransKey";
import NotFound from "./pages/NotFound";

function App() {

  
  return (
    <>
      <ToastContainer />
        <Routes>
          <Route path="/" element={<VehiclePage />} />
          <Route path="/uploads/license" element={<UploadPage />} />
          <Route path="/uploads/proof" element={<UploadProofPage />} />
          <Route path="/error-page" element={<NoTransKey />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
    </>
  );
}

export default App;
