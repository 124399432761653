import React from "react";
import { useNavigate } from "react-router";
import { customToast } from "../utils/customToast";
import { IoArrowBack } from "react-icons/io5";

export default function Uploads() {
  const navigate = useNavigate();
  const [video, setVideo] = React.useState({
    file: null,
    base64URL: "",
  });

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleUpload = () => {
    if (
      video?.file?.type?.includes("image/png") ||
      video?.file?.type?.includes("application/pdf") ||
      video?.file?.type?.includes("image/jpeg") ||
      video?.file?.type?.includes("image/jpg")
    ) {
      
      localStorage.setItem("video", JSON.stringify(video.file));
      customToast.success("File uploaded successfully");
      navigate("/uploads/proof");
    } else {
      customToast.error("File must be in png, jpg or pdf format.");
    }
  };

  const handleFileInputChange = (e) => {
    let { file } = video;

    file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setVideo({
          base64URL: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    setVideo({
      file: e.target.files[0],
    });
  };
  
  const handleBack = () => {
    window.history.back();
  };

  return (
    <div
      className="fixed top-0 left-0 z-[100] bg-cover bg-blend-overlay w-full h-full bg-black bg-opacity-80 flex justify-center items-center"
      style={{
        backgroundImage:
          "url(https://cdn.pixabay.com/photo/2017/10/12/22/17/business-2846221_1280.jpg)",
      }}
    >
      <div>
        <div className="absolute top-12 left-[12rem] xs:left-0 xs:right-0 ms:left-0 ms:right-0 md:left-[4rem] sm:left-[1rem] lg:left-[4rem] xl:left-[4rem]">
          <img
            src="https://octamile.com/assets/img/octamile_logo_blue.png"
            alt="logo"
            className="w-[189px] mx-auto"
          />
        </div>
        <div className="bg-[white] p-12 bg-white rounded-xl w-[800px] xs:w-[300px] ms:w-[300px] xs:-mt-[14rem] sm:w-[350px] md:w-[450px] lg:w-[500px] ">
          <div className="cursor-pointer" onClick={handleBack}>
            <IoArrowBack />
          </div>
          <p className="text-gray-600 text-[16px] mx-auto max-w-screen-md pb-8 text-center">
            Upload License
          </p>
          <label className="block border-dashed border-2 border-black/40 h-[213px] rounded-xl p-8 mb-6">
            <p className="text-center text-[#333333] text-sm">
              Click to <b>upload</b> or drag doc here
            </p>
            <p className="text-center text-xs">{video?.file?.name}</p>
            <input
              type="file"
              accept="image/png, image/jpeg, image/jpg, application/pdf"
              className="w-full text-center text-sm text-slate-500 file:hidden hidden"
              onChange={handleFileInputChange}
            />
          </label>
          <button
            className="w-full rounded bg-[#2f55d4] text-white text-sm font-[600] p-4"
            onClick={handleUpload}
          >
            {"Proceed"}
          </button>
        </div>
      </div>
    </div>
  );
}
