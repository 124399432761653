import React from "react";

export default function NoTransKey() {
  return (
    <div className="bg-[white] p-12 bg-white rounded-xl w-[1300px] mx-auto xs:w-[300px] ms:w-[300px] sm:w-[350px] md:w-[450px] lg:w-[500px] flex flex-col gap-[1rem] ">
      <div className="">
        <img
          src="https://octamile.com/assets/img/octamile_logo_blue.png"
          alt="logo"
          className="w-[189px]"
        />
      </div>
      <div className="flex flex-col gap-[1rem] mt-[2rem] ">
        <h1 className="text-[34px] text-[#364FEC] font-[700] ">
          No Transaction Key / Transaction Key is not correct
        </h1>
        <p className="text-[16px]  ">Invalid transaction reference</p>
      </div>
    </div>
  );
}
