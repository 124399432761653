import React from 'react';
import VehicleDetails from '../controllers/VehicleDetails';

export default function VehiclePage() {
  return (
    <div>
      <VehicleDetails />
    </div>
  )
}
