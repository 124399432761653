import React from "react";
import LogoImg from "../asset/Octamile for omo blue.svg";
import Loader from "../utils/Loader";

const PureComponent = React.memo(
  ({
    data,
    isLoading,
    handleClickButton,
    handleTextFieldChange,
    filledValues,
  }) => {

    return (
      <div className="w-[1300px] mx-auto xs:w-[250px] ms:w-[300px] sm:w-[400px] md:w-[600px] xl:w-[800px] lg:w-[700px] ">
        <div className="mt-[2rem] xs:flex xs:justify-center ms:flex ms:justify-center sm:flex sm:justify-center md:flex md:justify-center ">
          <img src={LogoImg} alt="" />
        </div>
        <div className="h-[700px] ">
          <h1 className="text-[25px] text-[#37517E] uppercase mb-[2rem] mt-[2rem] xs:flex xs:justify-center ms:flex ms:justify-center sm:flex sm:justify-center md:flex md:justify-center md:mt-[1rem] ">
            Vehicle Details: {data?.vehicle?.toUpperCase()}
          </h1>
          <div className="grid grid-cols-3 gap-[4rem] mt-[2rem] shadow-xl border-b-4 border-t-4 border-[#364FEC] px-8 py-[4rem] xs:grid xs:grid-cols-1 ms:grid ms:grid-cols-1 sm:grid sm:grid-cols-2 md:grid-cols-2 ">
            <div className="flex flex-col gap-[1rem] ">
              <h2 className="text-[16px] text-[#212529]">Customer:</h2>
              {isLoading ? (
                <Loader />
              ) : (
                <input
                  type="text"
                  className="text-[14px] border p-2 rounded-[8px] "
                  value={filledValues?.customer}
                  defaultValue={data?.customer}
                  onChange={handleTextFieldChange}
                  name="customer"
                />
              )}
            </div>
            <div className="flex flex-col gap-[1rem] ">
              <h2 className="text-[16px] text-[#212529]">Email:</h2>
              {isLoading ? (
                <Loader />
              ) : (
                <input
                  type="email"
                  className="text-[14px] border p-2 rounded-[8px] "
                  value={filledValues?.email}
                  defaultValue={data?.email}
                  onChange={handleTextFieldChange}
                  name="email"
                />
              )}
            </div>
            <div className="flex flex-col gap-[1rem] ">
              <h2 className="text-[16px] text-[#212529]">Phone:</h2>
              {isLoading ? (
                <Loader />
              ) : (
                <input
                  type="tel"
                  className="text-[14px] border p-2 rounded-[8px] "
                  value={filledValues?.phone}
                  defaultValue={data?.phone}
                  onChange={handleTextFieldChange}
                  name="phone"
                />
              )}
            </div>
            <div className="flex flex-col gap-[1rem] ">
              <h2 className="text-[16px] text-[#212529]">VIN:</h2>
              {isLoading ? (
                <Loader />
              ) : (
                <input
                  type="text"
                  className="text-[14px] border p-2 rounded-[8px] "
                  value={filledValues?.vin}
                  defaultValue={data?.vin}
                  onChange={handleTextFieldChange}
                  name="vin"
                />
              )}
            </div>
            <div className="flex flex-col gap-[1rem] ">
              <h2 className="text-[16px] text-[#212529]">Type of Vehicle:</h2>
              {isLoading ? (
                <Loader />
              ) : (
                <input
                  type="text"
                  className="text-[14px] border p-2 rounded-[8px] "
                  value={filledValues?.vehicle}
                  defaultValue={data?.vehicle}
                  onChange={handleTextFieldChange}
                  name="vehicle"
                />
              )}
            </div>
            <div className="flex flex-col gap-[1rem] ">
              <h2 className="text-[16px] text-[#212529]">Plate:</h2>
              {isLoading ? (
                <Loader />
              ) : (
                <input
                  type="text"
                  className="text-[14px] border p-2 rounded-[8px] "
                  value={filledValues?.plate}
                  defaultValue={data?.plate}
                  onChange={handleTextFieldChange}
                  name="plate"
                />
              )}
            </div>
            <div className="flex flex-col gap-[1rem] ">
              <h2 className="text-[16px] text-[#212529]">Policy End Date:</h2>
              {isLoading ? (
                <Loader />
              ) : (
                <input
                  type="text"
                  className="text-[14px] border p-2 rounded-[8px] "
                  value={filledValues?.policy_end_date}
                  defaultValue={data?.policy_end_date}
                  onChange={handleTextFieldChange}
                  name="policy_end_date"
                />
              )}
            </div>
            
            <div className="flex flex-col gap-[.5rem] ">
              <h2>Change Insurance Type:</h2>
              <div className="border p-2 mt-[1rem] rounded-[8px] w-full sm:w-full ">
                <select
                  name="policy_type"
                  id=""
                  className="outline-none border-none w-full sm:w-full"
                  onChange={handleTextFieldChange}
                >
                  <option value={data?.policy_type} defaultValue={data?.policy_type}>{data?.policy_type}</option>
                  <option value="third party">Third Party</option>
                  <option value="Comprehensive">Comprehensive</option>
                </select>
              </div>
            </div>
            <div>
              <button
                className="bg-[#364FEC] text-[#fff] text-[14px] font-[700] px-12 py-2 cursor-pointer rounded-[8px] "
                onClick={handleClickButton}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PureComponent;
