import React, {useEffect, useState, useMemo } from 'react';
import axios from 'axios';

export default function useCustomFetch(url) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getData = useMemo(
    () => () => {
      setIsLoading(true);
      axios
        .get(url)
        .then((data) => {
          setData(data.data);
        })
        .catch((error) => setError(error))
        .finally(() => setIsLoading(false));
    },
    [url]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return { data, error, isLoading };
}
