import React from "react";
import ThumbUp from "../asset/fa_thumbs-up.svg";
import { useNavigate } from "react-router-dom";

function SuccessModal({ onClose, successInfo }) {

  const navigate = useNavigate();

  const handleClick = () => {
    onClose();
    navigate(`/?transkey=${JSON.parse(localStorage.getItem('transkey'))}`);
  };

  return (
    <div
      className="fixed top-0 left-0 z-[100] bg-cover bg-blend-overlay w-full h-full bg-black bg-opacity-80 flex justify-center items-center "
      onClick={onClose}
      style={{
        backgroundImage:
          "url(https://cdn.pixabay.com/photo/2017/10/12/22/17/business-2846221_1280.jpg)",
      }}
    >
      <div
        className=""
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="py-[5%] ">
          <div className="p-6 bg-white w-[624px] rounded-xl mx-auto max-w-screen-lg sm:w-[600px] max-[600px]:w-[300px]">
            <div className="flex items-start justify-between">
              <div></div>
              <div>
                <img
                  src="https://octamile.com/assets/img/octamile_logo_blue.png"
                  alt="logo"
                  className="w-[149px] mx-auto"
                />
              </div>
              <div>
                <h1 className="text-[#333333] cursor-pointer " onClick={handleClick}>
                  X
                </h1>
              </div>
            </div>
            <div className="flex flex-col gap-[2rem]">
              <h1 className=" text-[24px] font-bold text-[#333333] text-center leading-[38.5px] my-1 ">
                Request Successful
              </h1>
              <div className="flex justify-center">
                <img src={ThumbUp} className="w-[70px] " alt="" />
              </div>
              <p className="w-full text-center text-[#333333] text-[20px] mx-auto px-4 sm:p-2 max-[600px]:p-2">
                {successInfo}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessModal;
