import React from 'react'
import Uploads from '../controllers/Uploads'

export default function UploadPage() {
  return (
    <div>
      <Uploads />
    </div>
  )
}
