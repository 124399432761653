import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCustomFetch from "../utils/useCustomFetch";
import PureComponent from "./PureComponent";

function VehicleDetails() {
  const navigate = useNavigate();

  const location = useLocation().search;
  const transkey = location.split("=")[1];

  const { data, isLoading } = useCustomFetch(
    `https://autorescue.ng/emails/fetch_vehicle.php?transkey=${transkey}`
  );

  const [filledValues, setFilledValues] = React.useState({
    customer: null,
    email: null,
    phone: null,
    vin: null,
    vehicle: null,
    plate: null,
    policy_end_date: null,
    policy_type: null,
    insurance_type: null,
  });

  const handleClickButton = () => {
    try {
      navigate("/uploads/license");
    } catch (error) {
      console.error(error);
    }
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFilledValues({ ...filledValues, [name]: value });
  }

  React.useEffect(() => {
    setFilledValues({
      customer: data?.customer,
      email: data?.email,
      phone: data?.phone,
      vin: data?.vin,
      vehicle: data?.vehicle,
      plate: data?.plate,
      policy_end_date: data?.policy_end_date,
      policy_type: data?.policy_type,
    })
  }, [data]);

  localStorage.setItem("userInfo", JSON.stringify(filledValues));
  localStorage.setItem("transkey", JSON.stringify(transkey));

  return (
    <div>
      <PureComponent data={data} handleTextFieldChange={handleTextFieldChange} handleClickButton={handleClickButton} isLoading={isLoading} setFilledValues={setFilledValues} />
    </div>
  );
}

export default VehicleDetails;
